:root {
  --main-color: #121212;
  --content-color: #333;
  --hover-color: #333;
  --title-font-size: 25px;
  --header-font-size: 20px;
  --content-font-size: 15px;
}

* {
  transition: all 1s ease-in-out;
  color: var(--main-color);
}

*::-webkit-scrollbar {
  display: none;
}

.app {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
}

.content {
  width: 80vw;
}

.sections {
  height: calc(100vh - 50px);
  position: relative;
  width: inherit;
  top: 50px;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.sections * {
  width: 100%;
  /* height: calc(100vh - 50px); */
  scroll-snap-align: start;
}

.topbar {
  font-size: var(--header-font-size);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: inherit;
  height: 50px;
  position: fixed;
  top: 0;
  border-bottom: 3px solid var(--main-color);
  z-index: 4;
}

.topbar ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.topbar a {
  text-decoration: none;
}

.topbar a:hover {
  font-weight: 500;
  color: var(--hover-color);
}

.home {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.homeTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#eName {
  font-size: 7vmax;
  font-family: 'Do Hyeon', sans-serif;
  text-align: center;
}
#cName {
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  font-size: 11vmax;
  text-align: center;
}

.homeBox {
  font-family: 'Do Hyeon', sans-serif;
  margin-top: 20px;
  text-align: center;
  font-size: 5vmax;
}

.homeBox::-webkit-scrollbar {
  display: none;
}

.learnMore {
  text-align: center;
}

.about {
  height: calc(100vh - 50px);
}

.projects {
  height: calc(100vh - 50px);
}

.title,
.aboutme {
  margin-top: 30px;
  font-size: 3vmax;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projectBox {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.proj {
  filter: grayscale(80%) brightness(0.5) blur(1px);
  width: 15vmax;
  min-width: 300px;
  min-height: 200px;
  margin: 10px;
  position: relative;
  top: 0;
  transition: top ease 1s;
  padding-top: 15px;
}

.pDetail {
  display: flex;
  font-size: 20px;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  color: var(--content-color);
}

.pImage {
  width: 300px;
  height: 165px;
  border-radius: 15px;
}

.proj:hover {
  filter: none;
  cursor: pointer;
  top: -10px;
}

.ptitle {
  font-size: var(--header-font-size);
}

.description {
  font-size: 15px;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.pgit {
  margin-top: 5px;
  width: 25px;
  margin-right: 15px;
}

.contact {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.greeting {
  text-align: center;
  margin: 10px auto;
  font-size: 5vmax;
}

.contactText {
  max-width: 600px;
  margin: 10px auto;
  font-size: var(--header-font-size);
  text-align: center;
}

.emailBtn {
  width: 90px;
  font-size: var(--header-font-size);
  margin: 10px auto;
  text-align: center;
  padding: 10px;
  border: 2px solid;
  border-radius: 5px;
  cursor: pointer;
}

.emailBtn a {
  text-decoration: none;
}

.aboutBox {
  display: grid;
  margin: 10px auto;
  max-width: 800px;
  grid-template-columns: 2fr 5fr;
}

.intro {
  max-width: 800px;
  margin: 20px auto;
  /* font-size: var(--header-font-size); */
  font-size: 22px;
  text-align: left;
}

.avatar img {
  max-width: min-content;
}

.linkContainter {
  width: 150px;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-content: center;
  margin: 10px 0;
}

.linkBtn {
  margin-top: 5px;
  width: 40px;
}

.apiLink {
  text-decoration: none;
}
